import { Navigate, Route, Routes } from "react-router-dom";
import Layout from "./components/Layout";
import Home from "./view/Home";
import About from "./view/About";
import Products from "./view/Products";
import Contact from "./view/Contact";
import Application from "./view/Application/Application";
import Catalog from "./view/Catalog";
import Events from "./view/Events";

function App() {
  return (
    <>
      <Routes>
        <Route element={<Layout />}>
          <Route index path="/" element={<Home />} />
          <Route path="/about" element={<About />} />
          <Route path="/products" element={<Products />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/application" element={<Application />} />
          <Route path="/catalogs" element={<Catalog />} />
          <Route path="/events" element={<Events />} />
          <Route path="*" element={<Navigate to="/" replace />} />
        </Route>
      </Routes>
    </>
  );
}

export default App;
