import Image from "../assets/images/contact-us.jpg";
import location from "../assets/images/location.png";
import phone from "../assets/images/phone.png";
import mail from "../assets/images/mail.png";
import chrome from "../assets/images/chrome.png";
import { useEffect } from "react";

function Contact() {
  useEffect(() => {
    // Scroll to the top of the page when the component mounts
    window.scrollTo(0, 0);
  }, []);

  return (
    <section className="">
      <div className="mb-14">
        <h1 className="absolute z-10 mt-60 ml-28 text-text text-center text-2xl xl:text-4xl font-semibold">
          <i>Contact Us</i>
        </h1>
        <img
          src={Image}
          alt="labco"
          className="relative h-[385px] top-[3.5rem] w-full object-cover"
        />
      </div>
      <div className="xl:flex md:w-11/12 px-2 justify-between">
        <div className="bg-side py-14 px-11 md:w-[35%] text-white">
          <p className="text-3xl font-bold pb-8">Let&apos;s get in touch</p>
          <p className="text-base pb-8">
            We&apos;re open for any suggestion or just to have a chat
          </p>
          <div>
            <p className="text-base flex">
              <span className="pr-6">
                <img src={location} alt="labco" className="w-20" />
              </span>
              Address : UAE-Dubai-Sheikh Rashid Road,Oud Metha Road-Metha Plaza
              Building B-Office B316
            </p>
            <p className="text-base flex py-4">
              <span className="pr-6">
                <img src={phone} alt="labco" className="w-7" />
              </span>
              Phone : <span className="text-text pl-1"> 971 4 2391777</span>
            </p>
            <p className="text-base flex py-4">
              <span className="pr-6">
                <img src={mail} alt="labco" className="w-7" />
              </span>
              Email :{" "}
              <span className="text-text pl-1">labcollc@labcoltd.ae</span>
            </p>
            <p className="text-base flex py-4">
              {" "}
              <span className="pr-6">
                <img src={chrome} alt="labco" className="w-7" />
              </span>
              Website : <span className="text-text pl-1">labcoltd.com</span>
            </p>
          </div>
        </div>
        <form className="xl:w-7/12 py-12 px-2">
          <div className="">
            <h1 className="text-2xl xl:text-4xl font-medium pb-6">
              Get in touch
            </h1>
          </div>
          <div className="md:flex justify-between pb-3">
            <div className="md:w-3/6 mr-2">
              <label htmlFor="firstName">
                Name <span className="text-red-500">*</span>
              </label>{" "}
              <br />
              <input
                type="text"
                name="firstname"
                placeholder="Your Name"
                className="border-b border-red-500 border-solid mt-3 p-2 outline-none w-full"
              />
            </div>
            <div className="md:w-3/6">
              <label htmlFor="lastName">
                Email<span className="text-red-500">*</span>
              </label>{" "}
              <br />
              <input
                type="email"
                name="email"
                placeholder="Your Email"
                className="border-b border-red-500 border-solid mt-3 p-2 outline-none w-full"
              />
            </div>
          </div>
          <div className="md:flex justify-between pb-3">
            <div className="md:w-3/6 md:mr-2">
              <label htmlFor="email">Phone</label> <br />
              <input
                type="number"
                name="email"
                placeholder="Phone #"
                className="border-b border-solid mt-3 p-2 outline-none w-full"
              />
            </div>
            <div className="md:w-3/6">
              <label htmlFor="email">Company</label> <br />
              <input
                type="email"
                name="email"
                placeholder="Company Name"
                className="border-b border-solid mt-3 p-2 outline-none w-full"
              />
            </div>
          </div>
          <div className="py-3">
            <label htmlFor="message">Message</label> <br />
            <textarea
              name="message"
              placeholder="Write Your Message"
              className="border-b border-solid mt-3 p-2 outline-none w-full"
            ></textarea>
          </div>
          <button
            type="submit"
            className="py-2 px-3 md:px-0 md:w-3/12 bg-blue text-white rounded"
          >
            Send Message
          </button>
        </form>
      </div>
      <div className="xl:w-full h-72 px-2 pb-1">
        <iframe
          title="map"
          src="https://www.google.com/maps/embed?pb=!1m17!1m12!1m3!1d3609.1361715117764!2d55.30939727376888!3d25.23233813027949!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m2!1m1!2zMjXCsDEzJzU2LjQiTiA1NcKwMTgnNDMuMSJF!5e0!3m2!1sen!2sae!4v1697899305470!5m2!1sen!2sae"
          width="100%"
          height="100%"
          style={{ border: 0 }}
          allowfullscreen=""
          loading="lazy"
          referrerPolicy="no-referrer-when-downgrade"
        ></iframe>
      </div>
    </section>
  );
}

export default Contact;
