const PDFView = ({ pdfUrl, title }) => {
  const handleDownload = () => {
    const a = document.createElement("a");
    a.href = pdfUrl;
    a.download = `${title}.pdf`; // Set the desired file name for download
    a.style.display = "none";
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
  };

  return (
    <div className="max-w-sm rounded-lg overflow-hidden">
      <object
        data={pdfUrl}
        type="application/pdf"
        className="w-full h-80"
      ></object>

      <div className="flex justify-between items-center my-3">
        <h2 className="text-text text-sm">{title}</h2>
        <button
          className="py-2 px-3 bg-side text-white text-sm rounded-full"
          onClick={handleDownload}
        >
          Download
        </button>
      </div>
    </div>
  );
};

export default PDFView;
