import { useState } from "react";
import { useNavigate } from "react-router-dom"; // Assuming you're using React Router for routing

const Search = () => {
  const [searchQuery, setSearchQuery] = useState("");
  const navigate = useNavigate();

  const handleSearch = () => {
    // Redirect to the products page with the search query as a query parameter
    navigate(`/products?search=${searchQuery}`);
    setSearchQuery("");
  };

  return (
    <div className="flex w-full">
      <input
        type="text"
        value={searchQuery}
        onChange={(e) => setSearchQuery(e.target.value)}
        placeholder="Type in Product Name, Product Number, CAS Number"
        className="w-10/12 border border-r-0 border-gray border-solid rounded-l-md p-2 placeholder:text-gray"
      />
      <button
        className="text-white flex bg-blue px-3 pt-2 pb-2 items-center border-gray border-solid border rounded-r-md"
        onClick={handleSearch}
      >
        Search{" "}
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="20"
          height="20"
          fill="currentColor"
          className="bi bi-search pl-1"
          viewBox="0 0 16 16"
        >
          <path d="M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001c.03.04.062.078.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1.007 1.007 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0z"></path>
        </svg>
      </button>
    </div>
  );
};

export default Search;
