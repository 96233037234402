import { useEffect } from "react";
import Image from "../assets/images/about-us.jpg";

function About() {
  useEffect(() => {
    // Scroll to the top of the page when the component mounts
    window.scrollTo(0, 0);
  }, []);

  return (
    <section className="">
      <div className="mb-16">
        <h1 className="absolute z-10 mt-60 ml-28 text-text text-center text-2xl xl:text-4xl font-semibold">
          <i>About Us</i>
        </h1>
        <img
          src={Image}
          alt=""
          className="relative h-[385px] top-[3.5rem] w-full object-cover"
        />
      </div>
      <p className="text-sm xl:text-base w-11/12 md:w-[89%] mx-auto pb-4">
        At LABCO, we take immense pride in our association with renowned and
        trusted brands such as AVANTOR, SIGMA, HONEYWELL, and MERCK. These
        esteemed partnerships underline our dedication to offering nothing but
        the best to our customers, ensuring access to products that adhere to
        the highest standards of quality and precision.
      </p>
      <p className="text-sm xl:text-base w-11/12 md:w-[89%] mx-auto pb-4">
        Our mission at LABCO is to empower researchers, scientists, and
        professionals across various domains with the tools and resources they
        need to drive innovation and make breakthroughs. We strive to foster a
        culture of continuous improvement, where customer satisfaction and
        scientific advancement remain at the core of our operations.
      </p>
      <p className="text-sm xl:text-base w-11/12 md:w-[89%] mx-auto pb-4">
        As we look ahead to the future, LABCO remains steadfast in our
        commitment to providing unparalleled service, innovative solutions, and
        superior products to the scientific community. Join us on this exciting
        journey as we continue to elevate the standards of excellence in the
        world of laboratory supplies and chemicals.
      </p>
    </section>
  );
}

export default About;
