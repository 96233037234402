import PDFView from "../components/PDFView";
import Analytical from "../assets/files/Analytical-Applications-Standards.pdf";
import Ascentis from "../assets/files/Ascentis-Express-HPLC-Resource-Guide.pdf";
import Bioactive from "../assets/files/Bioactive-Molecules-for-Stem-Cell-Biology.pdf";
import Biopharmaceutical from "../assets/files/Biopharmaceutical-production-chemicals-excepients.pdf";
import Cancer from "../assets/files/Cancer-Research.pdf";
import Chemistry from "../assets/files/Chemistry-Material-Science-Catalogue.pdf";
import ISOTEC from "../assets/files/ISOTEC-Stable-Isotopes.pdf";
import Karl from "../assets/files/Karl-Fischer-Titration-Overview.pdf";
import Life from "../assets/files/Life-Science.pdf";
import Mass from "../assets/files/Mass-Spectrometry-Proteomics.pdf";
import Water from "../assets/files/Water-Analysis-in-Food-and-Beverage.pdf";
import Whole from "../assets/files/Whole-Genome-Amplification.pdf";
import { useEffect } from "react";

function Catalog() {
  useEffect(() => {
    // Scroll to the top of the page when the component mounts
    window.scrollTo(0, 0);
  }, []);

  return (
    <section className="">
      <div className="grid md:grid-cols-3 gap-4 w-[93%] mx-auto mt-28">
        <PDFView
          pdfUrl={Analytical}
          title="Analytical-Applications-Standards"
        />
        <PDFView
          pdfUrl={Ascentis}
          title="Ascentis-Express-HPLC-Resource-Guide"
        />
        <PDFView
          pdfUrl={Bioactive}
          title="Bioactive-Molecules-for-Stem-Cell-Biology"
        />
        <PDFView
          pdfUrl={Biopharmaceutical}
          title="Biopharmaceutical-production-chemicals-excepients"
        />
        <PDFView pdfUrl={Cancer} title="Cancer-Research" />
        <PDFView
          pdfUrl={Chemistry}
          title="Chemistry-Material-Science-Catalogue"
        />
        <PDFView pdfUrl={ISOTEC} title="ISOTEC-Stable-Isotopes" />
        <PDFView pdfUrl={Karl} title="Karl-Fischer-Titration-Overview" />
        <PDFView pdfUrl={Life} title="Life-Science" />
        <PDFView pdfUrl={Mass} title="Mass-Spectrometry-Proteomics" />
        <PDFView pdfUrl={Water} title="Water-Analysis-in-Food-and-Beverage" />
        <PDFView pdfUrl={Whole} title="Whole-Genome-Amplification" />
      </div>
    </section>
  );
}

export default Catalog;
