const ResponsiveCard = ({ src, title, content }) => {
  return (
    <div className="max-w-sm rounded-lg overflow-hidden border border-solid">
      {/* Card Image */}
      <img className="w-full h-64" src={src} alt="Card" />

      {/* Card Content */}
      <div className="px-6 py-4 h-[27.5rem]">
        {/* Card Title */}
        <div className="font-bold text-xl mb-2 text-text">{title}</div>

        {/* Card Content */}
        <p className="text-gray-700 text-base">{content}</p>
      </div>
    </div>
  );
};

export default ResponsiveCard;
