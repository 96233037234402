import { Link } from "react-router-dom";

export default function Footer() {
  return (
    <div className="bg-footer text-white">
      <footer className="py-10 px-5 container mx-auto w-11/12 flex flex-col md:flex-row items-start justify-end">
        <div className=" my-4 md:mb-0 md:ml-4 md:w-5/12 md:border-r border-white border-solid">
          <div className="mb-4 md:mb-0">
            <h3 className="text-2xl font-bold">Applications</h3>
          </div>
          <div className="flex justify-start">
            <ul className="mt-2 mr-8">
              <li className="py-1">
                <Link className="text-xs">General Laboratory Reagents</Link>
              </li>
              <li className="py-1">
                <Link className="text-xs">Bio Applications</Link>
              </li>
              <li className="py-1">
                <Link className="text-xs">Life Science</Link>
              </li>
              <li className="py-1">
                <Link className="text-xs">Analytical Applications</Link>
              </li>
              <li className="py-1">
                <Link className="text-xs">Flavors and Fragrances</Link>
              </li>
            </ul>
            <ul className="mt-2">
              <li className="py-1">
                <Link className="text-xs">Labware</Link>
              </li>
              <li className="py-1">
                <Link className="text-xs">Karl-Fischer</Link>
              </li>
              <li className="py-1">
                <Link className="text-xs">Stable Isotopes</Link>
              </li>
              <li className="py-1">
                <Link className="text-xs">Chemistry & Materials Science</Link>
              </li>
              <li className="py-1">
                <Link className="text-xs">Analytical Standards</Link>
              </li>
            </ul>
          </div>
        </div>
        <div className="my-4 md:mb-0 md:ml-4 pr-8 md:w-[28%] md:border-r border-white border-solid">
          <h4 className="text-lg font-semibold">Technical Support</h4>
          <p className="mb-1 mt-4 font-bold">Support Department</p>
          <ul className="">
            <li className="py-1">
              <Link className="text-xs">
                <span className="text-sm font-bold">Telephone Number :</span>{" "}
                00971 54 7052026
              </Link>
            </li>
            <li className="py-1">
              <Link className="text-xs">
                <span className="text-sm font-bold">Fax Number :</span> 00971 4
                2391771
              </Link>
            </li>
            <li className="py-1">
              <Link className="text-xs">
                <span className="text-sm font-bold">Address :</span> P.O.Box
                13362, Dubai, U.A.E
              </Link>
            </li>
            <li className="py-1">
              <Link className="text-xs">
                <span className="text-sm font-bold">Email :</span>{" "}
                support@labcoltd.com
              </Link>
            </li>
          </ul>
        </div>
        <div className="my-4 md:mb-0 md:ml-4 pr-8 md:w-[28%] md:border-r border-white border-solid">
          <h4 className="text-lg font-semibold">Delivery Support</h4>
          <p className="mb-1 mt-4 font-bold">Delivery Team</p>
          <ul className=" ">
            <li className="py-1">
              <Link className="text-xs">
                <span className="text-sm font-bold">Telephone Number :</span>{" "}
                00971 54 7052027
              </Link>
            </li>
            <li className="py-1">
              <Link className="text-xs">
                <span className="text-sm font-bold">Fax Number :</span> 00971 4
                2391771
              </Link>
            </li>
            <li className="py-1">
              <Link className="text-xs">
                <span className="text-sm font-bold">Address :</span> P.O.Box
                13362, Dubai, U.A.E
              </Link>
            </li>
            <li className="py-1">
              <Link className="text-xs">
                <span className="text-sm font-bold">Email :</span>{" "}
                sales@labcoltd.com
              </Link>
            </li>
          </ul>
        </div>
      </footer>
      <div className="bg-copyright py-4 w-full text-white">
        <div className="block md:flex justify-between w-10/12  mx-auto text-xs">
          <p>© Copyright Labco LLC. All Rights Reserved.</p>
          <p>Authorized Partner of Merck, Sigma, Honeywell and VWR</p>
        </div>
      </div>
    </div>
  );
}
