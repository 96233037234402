// import { Link } from "react-router-dom";
import { useEffect } from "react";
import banner from "../assets/images/home-banner.jpg";

function Home() {
  useEffect(() => {
    // Scroll to the top of the page when the component mounts
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="max-h-full">
      <div>
        <div className="absolute z-10 text-white ml-[7rem] mt-36">
          <h1 className="text-big w-[41%] font-bold">
            <span className="text-text">
              HIGH <br /> QUALITY <br /> LAB CHEMICALS
            </span>
            &nbsp; FROM ACETONE TO ZINC
          </h1>
          <p className="font-semibold text-xl mt-3">
            Fueling innovation through high-quality research chemicals.
          </p>
        </div>

        <img
          src={banner}
          alt="Labco"
          loading="lazy"
          className="relative h-[500px] top-[5.5rem] w-full object-cover"
        />
      </div>
      <div className="mt-36 mb-4">
        <h2 className="text-7xl text-center mb-8 mt-10 text-text">
          Welcome to our website
        </h2>
        <div className="w-[88%] mx-auto text-base">
          <p>
            For over two decades, LABCO has been at the forefront of delivering
            excellence in the realm of chemicals and laboratory products. Since
            our inception, we have consistently upheld a commitment to providing
            top-tier solutions for professionals and institutions in the
            scientific community.
          </p>
          <p className="mt-3">
            As a pioneering force in the industry, LABCO has established a solid
            reputation for reliability, quality, and innovation. Our extensive
            range of products encompasses a wide array of essential laboratory
            supplies, chemicals, and cutting-edge equipment designed to meet the
            diverse needs of modern laboratories and research facilities.
          </p>
        </div>
      </div>
    </div>
  );
}

export default Home;
