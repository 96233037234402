import React, { useState, useEffect } from "react";
import head from "../../assets/images/product.jpg";
import up from "../../assets/images/up.png";
import down from "../../assets/images/down.png";
import { useNavigate, useLocation } from "react-router-dom";
import axios from "axios";

// axios.defaults.baseURL = process.env.REACT_APP_API_URL;
// const url = import.meta.env.VITE_REACT_APP_MY_URL;
const url = "https://test.themepalette.in/api";
// const url = "http://localhost:4000/api";
function Products() {
  const location = useLocation();
  const navigate = useNavigate();
  const searchParams = new URLSearchParams(location.search);
  const searchQuery = searchParams.get("search");
  const [expandedRow, setExpandedRow] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const rowsPerPage = 50;
  const [data, setData] = useState([]);
  const [totalPages, setTotalPages] = useState(0);
  const [loading, setLoading] = useState(false);
  const [isClicked, setIsClicked] = useState(false);

  useEffect(() => {
    // Scroll to the top of the page when the component mounts
    window.scrollTo(0, 0);
  }, []);

  const Loader = () => {
    return (
      <div className="fixed top-0 left-0 w-full h-full flex items-center justify-center bg-gray-900 bg-opacity-50 z-50">
        <div className="animate-spin rounded-full h-32 w-32 border-t-2 border-b-2 border-gray-100"></div>
      </div>
    );
  };

  useEffect(() => {
    // Function to fetch data from the search endpoint
    const fetchData = async () => {
      try {
        // Replace the URL with the search endpoint
        const response = await axios.get(
          `${url}/searchProduct?term=${searchQuery}`
        );
        // Handle the response data
        setData(response?.data?.records);
        setTotalPages(response?.data?.pagination?.totalPages);
      } catch (error) {
        // Handle any errors
        console.error("Error fetching data:", error);
      }
    };

    if (searchQuery) {
      // Call the search endpoint if a search query exists
      fetchData();
    } else {
      // Fetch the initial data from your regular endpoint
      axios
        .get(`${url}/product?page=${currentPage}&pageSize=${rowsPerPage}`)
        .then((response) => {
          // Handle the response data
          setData(response?.data?.records);
          setTotalPages(response?.data?.pagination?.totalPages);
        })
        .catch((error) => {
          // Handle any errors
          console.error("Error fetching data:", error);
        });
    }
  }, [searchQuery]);

  // Calculate the rows to display on the current page
  // Calculate pagination
  const nextPage = () => {
    setIsClicked(!isClicked);
    setLoading(true);
    setCurrentPage(currentPage + 1);
    if (searchQuery) {
      axios
        .get(
          `${url}/searchProduct?term=${searchQuery}&page=${
            currentPage + 1
          }&pageSize=${rowsPerPage}`
        )
        .then((response) => {
          setData(response?.data?.records);
          setTotalPages(response?.data?.pagination?.totalPages);
          setLoading(false);
          window.scrollTo(0, 300);
        })
        .catch((error) => {
          console.error("Error fetching data:", error);
        });
    } else {
      axios
        .get(`${url}/product?page=${currentPage}&pageSize=${rowsPerPage}`)
        .then((response) => {
          // Handle the response data
          console.log("response", response);
          setData(response?.data?.records);
          setTotalPages(response?.data?.pagination?.totalPages);
          setLoading(false);
          window.scrollTo(0, 300);
        })
        .catch((error) => {
          // Handle any errors
          console.error("Error fetching data:", error);
        });
    }
  };

  const prevPage = () => {
    setIsClicked(!isClicked);
    setLoading(true);
    setCurrentPage(currentPage - 1);
    if (searchQuery) {
      axios
        .get(
          `${url}/searchProduct?term=${searchQuery}&page=${
            currentPage - 1
          }&pageSize=${rowsPerPage}`
        )
        .then((response) => {
          setData(response?.data?.records);
          setTotalPages(response?.data?.pagination?.totalPages);
          setLoading(false);
          window.scrollTo(0, 300);
        })
        .catch((error) => {
          console.error("Error fetching data:", error);
        });
    } else {
      axios
        .get(`${url}/product?page=${currentPage}&pageSize=${rowsPerPage}`)
        .then((response) => {
          // Handle the response data
          console.log("response", response);
          setData(response?.data?.records);
          setTotalPages(response?.data?.pagination?.totalPages);
          setLoading(false);
        })
        .catch((error) => {
          // Handle any errors
          console.error("Error fetching data:", error);
        });
    }
  };

  const startIndex = (currentPage - 1) * rowsPerPage;
  const endIndex = startIndex + rowsPerPage;
  // const currentData = data.slice(startIndex, endIndex);

  // const filteredData = searchQuery
  //   ? Array.isArray(data)
  //     ? data.filter(
  //         (row) =>
  //           row.productNumber
  //             .toLowerCase()
  //             .includes(searchQuery.toLowerCase()) ||
  //           row.description.toLowerCase().includes(searchQuery.toLowerCase())
  //       )
  //     : []
  //   : data.slice(indexOfFirstRow, indexOfLastRow);

  // Define currentRows based on the presence of a searchQuery
  // const currentRows = searchQuery
  //   ? filteredData
  //   : data.slice(indexOfFirstRow, indexOfLastRow);

  console.log("data", data);

  // Total number of pages
  // const totalPages = Math.ceil(data.length / rowsPerPage);

  const handleRowClick = (id) => {
    console.log(id, "id");
    if (expandedRow === id) {
      console.log(id, "id inn");
      setExpandedRow(null); // Collapse the row if it's already expanded
    } else {
      console.log(id, "id out");
      setExpandedRow(id); // Expand the clicked row
    }
  };

  return (
    <section className="">
      <div className="mb-16">
        <h1 className="absolute z-10 mt-60 ml-28 text-text text-center text-2xl xl:text-4xl font-semibold">
          <i>Products</i>
        </h1>
        <img
          src={head}
          alt=""
          className="relative h-[385px] top-[3.5rem] w-full object-cover"
        />
      </div>
      <table className="w-[89%] mx-auto">
        <thead>
          <tr className="border-b text-left">
            <th className="w-3/12 pl-2 pb-2 font-bold">Product Number</th>
            <th className="w-8/12 pb-2 font-bold">Description</th>
            <th className="pb-2 font-bold">More Info</th>
          </tr>
        </thead>
        {loading ? (
          <Loader />
        ) : (
          <tbody>
            {data?.map((row) => (
              <React.Fragment key={row.id}>
                <tr className="text-sm border-b border-solid">
                  <td className="w-3/12 pl-2 py-2">{row.product_number}</td>
                  <td className="w-8/12 py-2">{row.description}</td>
                  <td className="py-2">
                    <span
                      className="bg-white flex items-center justify-center px-[10px] rounded-[3px] shadow-box cursor-pointer"
                      onClick={() => {
                        handleRowClick(row.id);
                      }}
                    >
                      {expandedRow === row.id ? (
                        <>
                          Hide
                          <img src={up} className="w-4 pl-2" alt="up" />
                        </>
                      ) : (
                        <>
                          Expand
                          <img src={down} className="w-4 pl-2" alt="down" />
                        </>
                      )}
                    </span>
                  </td>
                </tr>
                {expandedRow === row.id && (
                  <tr>
                    <td colSpan="3" className="bg-lblue p-2">
                      <div className="grid md:grid-cols-2 xl:grid-cols-4 text-sm">
                        <div>
                          <p className="py-1">CAS Number: {row.cas_number}</p>
                          <p className="py-2">
                            Shipped on ICE/no ICE: {row.shipped_ice}
                          </p>
                          <p className="py-2">UNSPSC Code: {row.unspsc_code}</p>
                          <p className="py-2">
                            Boiling Point: {row.bolling_point}
                          </p>
                          <p className="py-2">
                            Storage Temperature: {row.storage_temperature}
                          </p>
                          <p className="py-2">Density: {row.density}</p>
                          <p className="py-1">
                            Drug Precursor: {row.drug_precursor}
                          </p>
                        </div>
                        <div>
                          <p className="py-1">
                            Dual Use Material: {row.duel_use_meterial}
                          </p>
                          <p className="py-2">EINECSNo: {row.einecs_no}</p>
                          <p className="py-2">Flash Point: {row.flash_point}</p>
                          <p className="py-2">MDL Number: {row.mdl_number}</p>
                          <p className="py-2">
                            Melting Point: {row.melting_point}
                          </p>
                          <p className="py-2">
                            Molecular Formula: {row.molecular_formula}
                          </p>
                          <p className="py-1">
                            Molecular Weight: {row.molecular_weight}
                          </p>
                        </div>
                        <div>
                          <p className="py-1">
                            Narcotic Permission: {row.narcotic_permission}
                          </p>
                          <p className="py-2">Net Weight: {row.net_weight}</p>
                          <p className="py-2">
                            Gross Weight: {row.gross_weight}
                          </p>
                          <p className="py-2">
                            Unit Of Weight: {row.unit_of_weight}
                          </p>
                          <p className="py-2">
                            Purity Grade: {row.purity_grade}
                          </p>
                          <p className="py-2">R-Phrases: {row.r_phrases}</p>
                          <p className="py-1">
                            Radioactive Material: {row.radio_active_material}
                          </p>
                        </div>
                        <div>
                          <p className="py-1">
                            Specific Activity: {row.specific_activity}
                          </p>
                          <p className="py-2">Synonyms: {row.synonyms}</p>
                          <p className="py-2">
                            Tariff Number: {row.tariff_number}
                          </p>
                          <p className="py-2">
                            Technical Name: {row.technical_name}
                          </p>
                          <p className="py-2">Volume: {row.volume}</p>
                          <p className="py-2">
                            Unit Of Volume: {row.unit_of_volume}
                          </p>
                          <p className="py-1"> </p>
                        </div>
                      </div>
                    </td>
                  </tr>
                )}
              </React.Fragment>
            ))}
          </tbody>
        )}
      </table>

      {/* Pagination and "Next" and "Previous" buttons */}
      <div className="container mx-auto">
        <div className="flex justify-around items-center my-4">
          <button
            onClick={prevPage}
            disabled={currentPage === 1}
            className={`bg-blue ${
              isClicked ? "bg-text" : ""
            } text-white font-bold py-2 px-4 rounded`}
          >
            Previous
          </button>
          <ul>
            {/* {currentData.map((item, index) => (
            <li key={index}>{item}</li>
          ))} */}
            {currentPage} of {totalPages}
          </ul>
          <button
            onClick={nextPage}
            disabled={currentPage === totalPages}
            className={`bg-blue ${
              isClicked ? "bg-text" : ""
            } text-white font-bold py-2 px-4 rounded`}
          >
            Next
          </button>
        </div>
      </div>
    </section>
  );
}

export default Products;
