import { NavLink, Outlet } from "react-router-dom";
import logo from "../assets/images/LOGO.png";
import { useState } from "react";
import { Close, Menu } from "./icon";
import Footer from "./Footer";
import Search from "./Search";

function Layout() {
  const [isResponsive, setIsResponsive] = useState(false);

  const toggleResponsiveNav = () => {
    setIsResponsive(!isResponsive);
  };

  const closeResponsiveNav = () => {
    setIsResponsive(false); // Close the responsive navbar
  };

  const activeLink = "block py-2 px-4 text-text text-center";
  const normalLink = "block py-2 px-4 text-copyright text-center";

  const active = "block py-2 text-text";
  const normal = "block py-2 text-gray-400";

  return (
    <div className="font-poppins">
      <div
        className={`w-full fixed z-20 bg-white ${
          isResponsive ? "slide-down" : ""
        }`}
      >
        <div className="w-11/12 mx-auto hidden md:flex justify-between items-center">
          <div className="block py-2 px-3">
            <NavLink to={"/"}>
              <img src={logo} alt="Logo" className="h-14 w-13" />
            </NavLink>
          </div>
          <div
            className={`w-11/12 p-6 ${
              isResponsive ? "block" : "hidden"
            } md:flex items-center`}
          >
            <NavLink
              to={"/products"}
              className={({ isActive }) => (isActive ? activeLink : normalLink)}
            >
              Products
            </NavLink>
            <NavLink
              to={"/application"}
              className={({ isActive }) => (isActive ? activeLink : normalLink)}
            >
              Applications
            </NavLink>
            <NavLink
              to={"/about"}
              className={({ isActive }) => (isActive ? activeLink : normalLink)}
            >
              About
            </NavLink>
            <NavLink
              to={"/contact"}
              className={({ isActive }) => (isActive ? activeLink : normalLink)}
            >
              Contact
            </NavLink>
            <NavLink
              to={"/catalogs"}
              className={({ isActive }) => (isActive ? activeLink : normalLink)}
            >
              Catalogs
            </NavLink>
            {/* <NavLink
              to={"/events"}
              className={({ isActive }) => (isActive ? active : normal)}
              onClick={closeResponsiveNav}
            >
              Events
            </NavLink> */}
            <Search />
          </div>
          <button
            className="flex justify-start md:hidden py-2 px-4"
            onClick={toggleResponsiveNav}
          >
            {isResponsive ? <Close /> : <Menu />}
          </button>
        </div>
        <div className="w-11/12 mx-auto flex md:hidden justify-between items-center">
          <div className="block py-2 px-3">
            <NavLink to={"/"}>
              <img src={logo} alt="Logo" className="h-14 w-13" />
            </NavLink>
          </div>

          <button
            className="flex justify-start md:hidden py-2 px-4"
            onClick={toggleResponsiveNav}
          >
            {isResponsive ? <Close /> : <Menu />}
          </button>
        </div>
        <div className={`text-left p-6 ${isResponsive ? "block" : "hidden"}`}>
          <NavLink
            to={"/products"}
            className={({ isActive }) => (isActive ? active : normal)}
            onClick={closeResponsiveNav}
          >
            Products
          </NavLink>
          <NavLink
            to={"/application"}
            className={({ isActive }) => (isActive ? active : normal)}
            onClick={closeResponsiveNav}
          >
            Applications
          </NavLink>
          <NavLink
            to={"/about"}
            className={({ isActive }) => (isActive ? active : normal)}
            onClick={closeResponsiveNav}
          >
            About
          </NavLink>
          <NavLink
            to={"/contact"}
            className={({ isActive }) => (isActive ? active : normal)}
            onClick={closeResponsiveNav}
          >
            Contact
          </NavLink>
          <NavLink
            to={"/catalogs"}
            className={({ isActive }) => (isActive ? active : normal)}
            onClick={closeResponsiveNav}
          >
            Catalogs
          </NavLink>
          <NavLink
            to={"/events"}
            className={({ isActive }) => (isActive ? active : normal)}
            onClick={closeResponsiveNav}
          >
            Events
          </NavLink>
          <Search />
        </div>
      </div>
      <hr className="text-black" />
      <Outlet />
      <Footer />
    </div>
  );
}

export default Layout;
