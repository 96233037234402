import App from "../../assets/images/app-header.jpg";
import ResponsiveCard from "../../components/Card";
import General from "../../assets/images/general.jpg";
import Labware from "../../assets/images/labware.jpg";
import Bio from "../../assets/images/bio.jpg";
import Karl from "../../assets/images/Karl.jpg";
import Life from "../../assets/images/Life.jpg";
import Stable from "../../assets/images/stable.png";
import Analytical from "../../assets/images/analytical application.jpeg";
import Chemistry from "../../assets/images/chemistry.jpg";
import Flavors from "../../assets/images/Flavors.jpg";
import AnalyticalStandards from "../../assets/images/Analytical.jpg";
import { useEffect } from "react";

function Application() {
  useEffect(() => {
    // Scroll to the top of the page when the component mounts
    window.scrollTo(0, 0);
  }, []);

  return (
    <section className="">
      <div className="mb-16">
        <h1 className="absolute z-10 mt-60 ml-28 text-text text-center text-2xl xl:text-4xl font-semibold">
          <i>Application</i>
        </h1>
        <img
          src={App}
          alt=""
          className="relative h-[385px] top-[3.5rem] w-full object-cover"
        />
      </div>
      <div className="grid md:grid-cols-3 gap-4">
        {/* Card 1 */}
        <div className="bg-white p-4">
          <ResponsiveCard
            src={General}
            title={"General Laboratory Reagents"}
            content={
              "We offer a comprehensive portfolio of lab chemicals including acids, salts, caustic alkalis, bases, metals, solvents, and special products for analytical use in wet chemical labs, organic labs, and elsewhere. With strict quality controls and excellent batch-to-batch consistency, you can always expect reliable results. We also supply complementary lab chemicals for daily applications, such as drying agents, cleaning detergents, indicators, and auxiliaries. For maximum safety and simplicity during lab work, we provide specialized safety products, such as withdrawal systems, accessories, and powerful spill absorbents."
            }
          />
        </div>

        {/* Card 2 */}
        <div className="bg-white p-4">
          <ResponsiveCard
            src={Labware}
            title={"Labware"}
            content={
              "Laboratory equipment, glassware, and plasticware are essential for the success of any laboratory. Through our partnerships with brands you know and trust, we offer a wide selection of laboratory equipment and supplies to support more than 100 areas of interest."
            }
          />
        </div>

        {/* Card 3 */}
        <div className="bg-white p-4 d">
          <ResponsiveCard
            src={Bio}
            title={"Bio Applications"}
            content={
              "Molecular biology and functional genomic reagents and resources enable scientists to research the molecular machinery and pathways that drive all biological systems. From gene editing, DNA and RNA, oligos, qPCR, to protein expression, detection, and quantification, our comprehensive offering of world-class reagents are readily available for you to make discoveries in both healthy and disease tissues. Developed by our team of experts, explore our rich technical content, troubleshooting guides, and protocols and drive your research forward."
            }
          />
        </div>
        <div className="bg-white p-4 d">
          <ResponsiveCard
            src={Karl}
            title={"Karl-Fischer"}
            content={
              "With Aquastar® reagents and standards, the water content of solids, liquids, and gases can be determined effortlessly with a high degree of accuracy. Purchase our range of Aquastar® water standards for both volumetric and coulometric titrations."
            }
          />
        </div>
        <div className="bg-white p-4 d">
          <ResponsiveCard
            src={Life}
            title={"Life Science"}
            content={
              "Cell culture is fundamental in life science research and development. Whether your goal is to establish relevant cell models to probe complex biology, establish drug discovery and drug development assays, or produce recombinant proteins or therapeutics, we have everything you need to advance your science. Our cell culture workflow portfolio, advanced cell culture solutions, cell counting and imaging equipment, cultureware, extracellular matrices, hydrogels, sterile filtration consumables, reagents, media, sera, and growth factors offer quality essentials for 2D and 3D cell culture experiments"
            }
          />
        </div>
        <div className="bg-white p-4 d">
          <ResponsiveCard
            src={Stable}
            title={"Stable Isotopes"}
            content={
              "Stable Isotopes are stable isotope compounds, ranging from gases to complex molecules. These compounds are useful for tracer studies in proteomics and metabolomics, agents for MRI / MRS, and in a wide range of other biomedical applications.Both tracer and flux studies benefit from stable isotope label assistance. Metabolic pathways can be studied by tracking naturally-occurring and disease-indicated metabolites. Additionally, the effects of exogeneous substances or perturbations on the metabolome can be elucidated with the use of our stable isotope-labeled internal standards coupled with mass spectroscopy determination."
            }
          />
        </div>
        <div className="bg-white p-4 d">
          <ResponsiveCard
            src={Analytical}
            title={"Analytical Applications"}
            content={
              "We offer analytical reagents for wet chemistry, chromatography, spectroscopy, analytical microbiology, microscopy, titration, water quality control, and electrochemistry. Our offering includes high-purity acids, bases, and solvents, and specialty reagents for a number of analytical applications, such as ionophores, Karl Fischer titration, and derivatization reagents. We offer a variety of purity grades, including ACS to high-purity reagents for ultra-trace analysis."
            }
          />
        </div>
        <div className="bg-white p-4 d">
          <ResponsiveCard
            src={Chemistry}
            title={"Chemistry & Materials Science"}
            content={
              "We provide ready-to-use nanomaterials, including functionalized nanoparticles, quantum dots, carbon nanomaterials, boron nitride nanotubes, perovskites, and OLED materials. Our biomedical materials comprise degradable polymers, natural polymers, block copolymers, hydrogels, and PEGs. Our high-performance energy materials and electronics materials have advanced semiconducting capacity and power density. Our high-purity metal salts, deposition precursors, metals, alloys, oxides, monomers, polymers, initiators, and additional polymerization tools ensure the synthesis of high-quality materials."
            }
          />
        </div>
        <div className="bg-white p-4 d">
          <ResponsiveCard
            src={Flavors}
            title={"Flavors and Fragrances"}
            content={
              "Aroma chemicals enhance the flavor or fragrance in a wide range of consumer products. Our certified food-grade, IFRA (International Fragrance Association), natural, kosher, and halal ingredients undergo scientific analysis and dedicated testing to provide safe and high-quality aroma chemicals for product formulation and development. A collaborative approach towards qualified vendors and compliance with internationally recognized regulatory requirements helps you meet the requirements of your customer."
            }
          />
        </div>
        <div className="bg-white p-4 d">
          <ResponsiveCard
            src={AnalyticalStandards}
            title={"Analytical Standards"}
            content={
              "Analytical chemistry studies and uses instruments and methods to separate, identify, and quantify matter.[1] In practice, separation, identification or quantification may constitute the entire analysis or be combined with another method. Separation isolates analytes. Qualitative analysis identifies analytes, while quantitative analysis determines the numerical amount or concentration."
            }
          />
        </div>

        {/* Repeat for the other cards */}
      </div>
    </section>
  );
}

export default Application;
